import { match, compile } from 'path-to-regexp'
// import config from '../../../../config'
import translatejson from '../../../../translation.json'
import { getIsServer } from '../utillities/getIsServer'
import { getJobQueryFromSEOPathNameV2, toSEOPathNameV2 } from '../utillities/SEOUtilities'
import { useTranslation } from './useTranslation'

const LinkResolverHandleMap = {
  JobSearchSlugName: (pathName, lang) => {
    const { search, locationId, categoryId } = getJobQueryFromSEOPathNameV2(pathName)
    return toSEOPathNameV2({ search, locationId, categoryId }, lang)
  }
}

export const translateRouterHref = (href: string, lang: string) => {
  const [pathName, searchQuery] = href ? href?.split('?') : []
  const translateRouter = translatejson.find((tranR) => {
    return match(tranR.default)(pathName) || (tranR.vi && match(tranR.vi)(pathName)) || (tranR.en && match(tranR.en)(pathName))
  })
  if (translateRouter) {
    const matcherSource =
      match<any>(translateRouter.default)(pathName) ||
      (translateRouter.en && match<any>(translateRouter.en)(pathName)) ||
      (translateRouter.vi && match<any>(translateRouter.vi)(pathName))

    if (!translateRouter[lang]) {
      return href
    }

    //@ts-ignore
    const translateHref = compile(translateRouter[lang])(matcherSource.params)
    return translateHref + (searchQuery ? `?${searchQuery}` : ``)
  }
  return href
}

let translateHandler
export const setTranslateHandler = (func: () => void) => {
  translateHandler = func
}
export const clearTranslateHandler = () => (translateHandler = undefined)

export const getTranslateLocale = (pathName, nextLocale) => {
  const nomalize = pathName.replace(/\/$/g, '')
  const translateRouter = translatejson.find((tranR) => {
    return match(tranR.default)(nomalize) || (tranR.vi && match(tranR.vi)(nomalize)) || (tranR.en && match(tranR.en)(nomalize))
  })
  if (getIsServer()) {
    return nextLocale === 'eng' ? 'en' : nextLocale
  }
  if (translateRouter) {
    return match(translateRouter.vi)(nomalize) ? 'vi' : 'en'
  } else {
    return nextLocale === 'eng' ? 'en' : nextLocale
  }
}
export const getPathSpecificLanguage = (pathName, nextLocale) => {
  const nomalize = pathName.replace(/\/$/g, '')

  const translateRouter = translatejson.find((tranR) => {
    return match(tranR.default)(nomalize) || (tranR.vi && match(tranR.vi)(nomalize)) || (tranR.en && match(tranR.en)(nomalize))
  })
  if (translateRouter) {
    if (match(translateRouter.default)(nomalize)) {
      return nextLocale === 'eng' ? 'en' : 'vi'
    }
    return match(translateRouter.vi)(nomalize) ? 'vi' : 'en'
  }
}
export const getTranslateRouterHref = (
  href: string,
  lang: string
): { href: string; defaultPath?: string; query?: { [key: string]: string } } => {
  const [pathName, searchQuery] = href ? href?.split('?') : []
  const translateRouter = translatejson.find((tranR) => {
    return match(tranR.default)(pathName) || (tranR.vi && match(tranR.vi)(pathName)) || (tranR.en && match(tranR.en)(pathName))
  })
  if (translateRouter) {
    const matcherSource =
      match<any>(translateRouter.default)(pathName) ||
      (translateRouter.en && match<any>(translateRouter.en)(pathName)) ||
      (translateRouter.vi && match<any>(translateRouter.vi)(pathName))
    const translateCustomSlug = translateHandler ? { slug: translateHandler(lang) } : undefined

    if (!translateRouter[lang]) {
      return { href, defaultPath: translateRouter.default }
    }

    //@ts-ignore
    const translateHref = compile(translateRouter[lang])(translateCustomSlug || matcherSource.params)
    // const defaultHref = compile(translateRouter.default)(translateCustomSlug || matcherSource.params)

    return {
      href: translateHref + (searchQuery ? `?${searchQuery}` : ``),
      defaultPath: translateRouter.default + (searchQuery ? `?${searchQuery}` : ``)
      // query: translateCustomSlug || defaultHref
    }
  }
  return { href }
}

export const useTranslateLink = () => {
  const { currentLanguage } = useTranslation()
  return (href: string) => {
    const url = new URL(href)
    url.pathname = translateRouterHref(url.pathname, currentLanguage)
    return url.toString()
  }
}
