import { NextRouter, useRouter } from 'next/router'
import { useMemo } from 'react'
import { getPathSpecificLanguage, getTranslateRouterHref } from 'src/@legacy/@optimal-page/hooks/useTranslateLink'
import { useGetAppRealPath, useServerService } from '../components/FreecProvider'
import { DEFAULT_LOCALE } from '../utilities/Constant'
import { COOKIES_NAME_LANGUAGE, getCookie } from '../utilities/Cookies'
import { getIsServer } from '../utilities/SSR'
export const useAppRouter = (): NextRouter => {
  const router = useRouter()
  const getAppRealPath = useGetAppRealPath()
  const serverService = useServerService()
  const realPath = getAppRealPath()
  const locale =
    getPathSpecificLanguage(realPath, router?.locale) ||
    (getIsServer()
      ? serverService
        ? serverService.getCookie(COOKIES_NAME_LANGUAGE)
        : router?.locale
      : getCookie(COOKIES_NAME_LANGUAGE)) ||
    DEFAULT_LOCALE
  const push: typeof router.push = (url, as, options) => {
    const { href: translatedLink, defaultPath } = getTranslateRouterHref(url as string, options?.locale || locale)
    if (defaultPath) {
      return router.push(defaultPath, as || translatedLink, { ...options, locale: false })
    }
    return router.push(translatedLink, as || translatedLink, { ...options, locale: false })
  }
  return useMemo(
    () => ({
      ...router,
      push
    }),
    [router]
  )
}
